<template>
  <div class="fourteen wide column">
    <h2 class="ui horizontal divider header">
      PROJETS
    </h2>

    <div class="flex">
      <router-link
        v-if="user && user.can_create_project && isOffline() != true"
        :to="{ name: 'project_create', params: { action: 'create' } }"
        class="ui green basic button"
      >
        <i class="plus icon" /> Créer un nouveau projet
      </router-link>
      <router-link
        v-if="user && user.can_create_project && isOffline() != true"
        :to="{
          name: 'project_type_list',
        }"
        class="ui blue basic button"
      >
        <i class="copy icon" /> Accéder à la liste des modèles de projets
      </router-link>
    </div>

    <!-- FILTRES DES PROJETS -->
    <ProjectsMenu
      @filter="setProjectsFilters"
      @loading="setLoader"
    />

    <div
      v-if="configuration.DISPLAY_FORBIDDEN_PROJECTS"
      id="forbidden-projects"
      class="ui toggle checkbox"
    >
      <input
        v-model="displayForbiddenProjects"
        type="checkbox"
      >
      <label>
        N'afficher que les projets disponibles à la consultation
      </label>
    </div>

    <!-- LISTE DES PROJETS -->
    <div
      v-if="projects"
      class="ui divided items dimmable dimmed"
    >
      <div
        :class="{ active: loading }"
        class="ui inverted dimmer"
      >
        <div class="ui loader" />
      </div>
      <div
        v-for="project in projects"
        :key="project.slug"
        class="item"
      >
        <div class="ui tiny image">
          <img
            :src="
              !project.thumbnail
                ? require('@/assets/img/default.png')
                : DJANGO_BASE_URL + project.thumbnail + refreshId()
            "
          >
        </div>
        <div class="middle aligned content">
          <router-link
            :to="{
              name: 'project_detail',
              params: { slug: project.slug },
            }"
            class="header"
          >
            {{ project.title }}
          </router-link>

          <div class="description">
            <p>{{ project.description }}</p>
          </div>
          <div class="meta">
            <span class="right floated">
              <strong v-if="project.moderation">Projet modéré</strong>
              <strong v-else>Projet non modéré</strong>
            </span>
            <span>Niveau d'autorisation requis :
              {{ project.access_level_pub_feature }}</span><br>
            <span v-if="user">
              Mon niveau d'autorisation :
              <span v-if="USER_LEVEL_PROJECTS && project">{{
                USER_LEVEL_PROJECTS[project.slug]
              }}</span>
              <span v-if="user && user.is_administrator">{{
                "+ Gestionnaire métier"
              }}</span>
            </span>
          </div>
          <div class="meta">
            <span class="right floated">
              <i class="calendar icon" />&nbsp; {{ project.created_on }}
            </span>
            <span data-tooltip="Membres">
              {{ project.nb_contributors }}&nbsp;<i class="user icon" />
            </span>
            <span data-tooltip="Signalements publiés">
              {{ project.nb_published_features }}&nbsp;<i
                class="map marker icon"
              />
            </span>
            <span data-tooltip="Commentaires">
              {{ project.nb_published_features_comments }}&nbsp;<i
                class="comment icon"
              />
            </span>
          </div>
        </div>
      </div>

      <span
        v-if="!projects || projects.length === 0"
      >Vous n'avez accès à aucun projet.</span>

      <div class="item" />
    </div>

    <!-- PAGINATION -->
    <pagination
      v-if="count"
      :nb-pages="Math.ceil(count/10)"
      :on-page-change="SET_CURRENT_PAGE"
      @change-page="changePage"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

import ProjectsMenu from '@/components/Projects/ProjectsMenu.vue';
import Pagination from '@/components/Pagination.vue';

export default {
  name: 'Projects',

  components: {
    ProjectsMenu,
    Pagination
  },
  
  data() {
    return {
      loading: false,
      displayForbiddenProjects: false
    };
  },

  computed: {
    ...mapState([
      'configuration',
      'user',
      'USER_LEVEL_PROJECTS'
    ]),
    ...mapState('projects', [
      'projects',
      'count',
      'filters'
    ]),
    APPLICATION_NAME() {
      return this.$store.state.configuration.VUE_APP_APPLICATION_NAME;
    },
    APPLICATION_ABSTRACT() {
      return this.$store.state.configuration.VUE_APP_APPLICATION_ABSTRACT;
    },
    DJANGO_BASE_URL() {
      return this.$store.state.configuration.VUE_APP_DJANGO_BASE;
    },
    logo() {
      return this.$store.state.configuration.VUE_APP_LOGO_PATH;
    },
  },

  watch: {
    filters: {
      deep: true,
      handler(newValue) {
        if (newValue) {
          this.getData();
        }
      }
    },
    displayForbiddenProjects(newValue) {
      if (newValue) {
        this.SET_PROJECTS_FILTER({
          filter: 'accessible',
          value: 'true'
        });
      } else {
        this.SET_PROJECTS_FILTER({
          filter: 'accessible',
          value: null
        });
      }
      this.getData();
    }
  },

  created() {
    this.$store.commit('projects/SET_PROJECT', null);
    this.SET_PROJECTS_FILTER({
      filter: 'accessible',
      value: 'true'
    });
    this.displayForbiddenProjects = this.configuration.DISPLAY_FORBIDDEN_PROJECTS_DEFAULT;
  },

  methods: {
    ...mapMutations('projects', [
      'SET_CURRENT_PAGE',
      'SET_PROJECTS_FILTER'
    ]),
    ...mapActions('projects', [
      'GET_PROJECTS'
    ]),

    isOffline() {
      return navigator.onLine == false;
    },
    refreshId() {
      //* change path of thumbnail to update image
      return '?ver=' + Math.random();
    },

    getData(page) {
      this.loading = true;
      this.GET_PROJECTS(page)
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    setLoader(e) {
      this.loading = e;
    },

    changePage(e) {
      this.getData(e);
    },

    setProjectsFilters(e) {
      this.SET_PROJECTS_FILTER(e);
    },
  }
};
</script>

<style lang="less" scoped>

.flex {
  display: flex;
  justify-content: space-between;
}

#filters-divider {
  padding-top: 0;
  color: gray !important;
}

#forbidden-projects.checkbox {
  font-size: 1.2em;
  font-weight: 600;
  label {
    color: rgb(94, 94, 94);
  }
  input:checked ~ label::before {
    background-color: teal !important;
  }
  input:checked ~ label {
    color: teal !important;
  }
}

</style>
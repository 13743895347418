import { render, staticRenderFns } from "./ProjectsMenu.vue?vue&type=template&id=195fa058&scoped=true&"
import script from "./ProjectsMenu.vue?vue&type=script&lang=js&"
export * from "./ProjectsMenu.vue?vue&type=script&lang=js&"
import style0 from "./ProjectsMenu.vue?vue&type=style&index=0&id=195fa058&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "195fa058",
  null
  
)

export default component.exports
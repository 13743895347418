var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fourteen wide column"},[_c('h2',{staticClass:"ui horizontal divider header"},[_vm._v(" PROJETS ")]),_c('div',{staticClass:"flex"},[(_vm.user && _vm.user.can_create_project && _vm.isOffline() != true)?_c('router-link',{staticClass:"ui green basic button",attrs:{"to":{ name: 'project_create', params: { action: 'create' } }}},[_c('i',{staticClass:"plus icon"}),_vm._v(" Créer un nouveau projet ")]):_vm._e(),(_vm.user && _vm.user.can_create_project && _vm.isOffline() != true)?_c('router-link',{staticClass:"ui blue basic button",attrs:{"to":{
        name: 'project_type_list',
      }}},[_c('i',{staticClass:"copy icon"}),_vm._v(" Accéder à la liste des modèles de projets ")]):_vm._e()],1),_c('ProjectsMenu',{on:{"filter":_vm.setProjectsFilters,"loading":_vm.setLoader}}),(_vm.configuration.DISPLAY_FORBIDDEN_PROJECTS)?_c('div',{staticClass:"ui toggle checkbox",attrs:{"id":"forbidden-projects"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.displayForbiddenProjects),expression:"displayForbiddenProjects"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.displayForbiddenProjects)?_vm._i(_vm.displayForbiddenProjects,null)>-1:(_vm.displayForbiddenProjects)},on:{"change":function($event){var $$a=_vm.displayForbiddenProjects,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.displayForbiddenProjects=$$a.concat([$$v]))}else{$$i>-1&&(_vm.displayForbiddenProjects=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.displayForbiddenProjects=$$c}}}}),_c('label',[_vm._v(" N'afficher que les projets disponibles à la consultation ")])]):_vm._e(),(_vm.projects)?_c('div',{staticClass:"ui divided items dimmable dimmed"},[_c('div',{staticClass:"ui inverted dimmer",class:{ active: _vm.loading }},[_c('div',{staticClass:"ui loader"})]),_vm._l((_vm.projects),function(project){return _c('div',{key:project.slug,staticClass:"item"},[_c('div',{staticClass:"ui tiny image"},[_c('img',{attrs:{"src":!project.thumbnail
              ? require('@/assets/img/default.png')
              : _vm.DJANGO_BASE_URL + project.thumbnail + _vm.refreshId()}})]),_c('div',{staticClass:"middle aligned content"},[_c('router-link',{staticClass:"header",attrs:{"to":{
            name: 'project_detail',
            params: { slug: project.slug },
          }}},[_vm._v(" "+_vm._s(project.title)+" ")]),_c('div',{staticClass:"description"},[_c('p',[_vm._v(_vm._s(project.description))])]),_c('div',{staticClass:"meta"},[_c('span',{staticClass:"right floated"},[(project.moderation)?_c('strong',[_vm._v("Projet modéré")]):_c('strong',[_vm._v("Projet non modéré")])]),_c('span',[_vm._v("Niveau d'autorisation requis : "+_vm._s(project.access_level_pub_feature))]),_c('br'),(_vm.user)?_c('span',[_vm._v(" Mon niveau d'autorisation : "),(_vm.USER_LEVEL_PROJECTS && project)?_c('span',[_vm._v(_vm._s(_vm.USER_LEVEL_PROJECTS[project.slug]))]):_vm._e(),(_vm.user && _vm.user.is_administrator)?_c('span',[_vm._v(_vm._s("+ Gestionnaire métier"))]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"meta"},[_c('span',{staticClass:"right floated"},[_c('i',{staticClass:"calendar icon"}),_vm._v("  "+_vm._s(project.created_on)+" ")]),_c('span',{attrs:{"data-tooltip":"Membres"}},[_vm._v(" "+_vm._s(project.nb_contributors)+" "),_c('i',{staticClass:"user icon"})]),_c('span',{attrs:{"data-tooltip":"Signalements publiés"}},[_vm._v(" "+_vm._s(project.nb_published_features)+" "),_c('i',{staticClass:"map marker icon"})]),_c('span',{attrs:{"data-tooltip":"Commentaires"}},[_vm._v(" "+_vm._s(project.nb_published_features_comments)+" "),_c('i',{staticClass:"comment icon"})])])],1)])}),(!_vm.projects || _vm.projects.length === 0)?_c('span',[_vm._v("Vous n'avez accès à aucun projet.")]):_vm._e(),_c('div',{staticClass:"item"})],2):_vm._e(),(_vm.count)?_c('pagination',{attrs:{"nb-pages":Math.ceil(_vm.count/10),"on-page-change":_vm.SET_CURRENT_PAGE},on:{"change-page":_vm.changePage}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import axios from '@/axios-client.js';
import projectAPI from '@/services/project-api';

const projects = {

  namespaced: true,

  state: {
    count: 0,
    currentPage: 1,
    filters: {
      moderation: null,
      access_level: null,
      user_access_level: null,
      accessible: null
    },
    isProjectsListSearched: null,
    last_comments: [],
    projects: [],
    project: null,
    project_slug: null,
    searchProjectsFilter: null,
  },

  getters: {
    project_types: state => state.projects.filter(projet => projet.is_project_type),
    project_user: state => state.projects.filter(projet => projet.creator === state.user.id),
  },

  mutations: {
    SET_CURRENT_PAGE (state, payload) {
      state.currentPage = payload;
    },

    SET_PROJECTS(state, projects) {
      if (projects.results) {
        state.projects = projects.results;
        state.count = projects.count;
      } else {
        state.projects = projects;
        state.count = projects.length;
      }
    },

    ADD_PROJECT(state, project) {
      state.projects = [project, ...state.projects];
    },

    SET_PROJECT(state, project) {
      state.project = project;
    },

    SET_PROJECTS_FILTER(state, payload) {
      state.filters[payload.filter] = payload.value;
    },

    SET_PROJECTS_SEARCH_STATE(state, payload) {
      state.isProjectsListSearched = payload.isSearched;
      state.searchProjectsFilter = payload.text;
    },

    SET_PROJECT_COMMENTS(state, last_comments) {
      state.last_comments = last_comments;
    },
  },

  actions: {
    async GET_ALL_PROJECTS({ rootState, commit }) {
      try {
        const response = await axios
          .get(`${rootState.configuration.VUE_APP_DJANGO_API_BASE}projects/`);
        if (response.status === 200 && response.data) {
          commit('SET_PROJECTS', response.data);
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    async GET_PROJECTS({ state, rootState, commit }, page) {
      if (!page) {
        page = state.currentPage;
      }
      const baseUrl = rootState.configuration.VUE_APP_DJANGO_API_BASE;
      const projects = await projectAPI.getProjects(baseUrl, state.filters, page);
      commit('SET_PROJECTS', projects);
    },

    async SEARCH_PROJECTS({ commit, dispatch }, text) {
      if (text) {
        await dispatch('HANDLE_PROJECTS_SEARCH_REQUEST', text);
      } else {
        commit('SET_PROJECTS_SEARCH_STATE', {
          isSearched: false,
          text: null
        });
        await dispatch('GET_PROJECTS');
      }
    },

    async GET_PROJECT({ rootState, commit }, slug) {
      const baseUrl = rootState.configuration.VUE_APP_DJANGO_API_BASE;
      const project = await projectAPI.getProject(baseUrl, slug);
      commit('SET_PROJECT', project);
      return project;
    },

    async GET_PROJECT_INFO({ rootState, dispatch }, slug) {
      let promises = [
        dispatch('GET_PROJECT_LAST_MESSAGES', slug).then(response => response),
        dispatch('feature_type/GET_PROJECT_FEATURE_TYPES', slug, { root: true }).then(response => response),
      ];
      if (rootState.user) promises.push(dispatch('map/GET_BASEMAPS', slug, { root: true }).then(response => response));

      const promiseResult = await Promise.all(promises);
      return promiseResult;
    },

    GET_PROJECT_LAST_MESSAGES({ commit }, project_slug) {
      return axios
        .get(`${this.state.configuration.VUE_APP_DJANGO_API_BASE}projects/${project_slug}/comments/`)
        .then((response) => {
          if (response && response.status === 200) {
            commit('SET_PROJECT_COMMENTS', response.data.last_comments);
          }
          return response;
        })
        .catch((error) => {
          throw error;
        });
    },

    async HANDLE_PROJECTS_SEARCH_REQUEST({ state, rootState, commit }, text) {

      if (rootState.cancellableSearchRequest.length > 0) {
        const currentRequestCancelToken =
          rootState.cancellableSearchRequest[rootState.cancellableSearchRequest.length - 1];
        currentRequestCancelToken.cancel();
      }
  
      const cancelToken = axios.CancelToken.source();
      commit('SET_CANCELLABLE_SEARCH_REQUEST', cancelToken, { root: true });
  
      const url = `${rootState.configuration.VUE_APP_DJANGO_API_BASE}projects/?search=${text}`;
      let filteredUrl;
      if (Object.values(state.filters).some(el => el && el.length > 0)) {
        filteredUrl = url;
        for (const filter in state.filters) {
          if (state.filters[filter]) {
            filteredUrl = filteredUrl.concat('', `&${filter}=${state.filters[filter]}`);
          }
        }
      }
  
      const response = await axios.get(
        filteredUrl ? filteredUrl : url,
        {
          cancelToken: cancelToken.token,
        }
      );
      if (response.status === 200) {
        const projects = response.data;
        if (projects) {
          commit('SET_PROJECTS', projects);
          commit('SET_PROJECTS_SEARCH_STATE', {
            isSearched: true,
            text: text
          });
        }
      }
    },
  }

};

export default projects;

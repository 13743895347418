import axios from '@/axios-client.js';

const getColorsStyles = (customForms) => customForms
  .filter(customForm => customForm.options && customForm.options.length)
  .map(el => {
  //* in dropdown, value is the name and name is the label to be displayed, could be changed...
    return { value: el.name, name: el.label, options: el.options };
  });

const pending2draftFeatures = (features) => {
  let result = [];
  for (let el of features) {
    if (el.properties.status === 'pending') {
      el.properties.status = 'draft';
    }
    result.push(el);
  }
  return result;
};

const feature_type = {
  namespaced: true,
  state: {
    form: null,
    colorsStyleList: [],
    customForms: [],
    current_feature_type_slug: null,
    feature_types: [],
    fileToImport: null,
    importFeatureTypeData: [],
  },

  mutations: {
    SET_FEATURE_TYPES(state, feature_types) {
      state.feature_types = feature_types;
    },
    SET_CURRENT_FEATURE_TYPE_SLUG(state, payload) {
      //* reset import status of features for a specific feature_type when changing
      state.importFeatureTypeData = null;
      state.current_feature_type_slug = payload;
    },
    UPDATE_FORM(state, payload) {
      state.form = payload;
    },
    EMPTY_FORM(state) {
      state.form = null;
    },
    SET_CUSTOM_FORMS(state, customForms) {
      state.customForms = customForms;
      state.colorsStyleList = getColorsStyles(state.customForms);
    },
    ADD_CUSTOM_FORM(state, customForm) {
      state.customForms = [...state.customForms, customForm];
      state.colorsStyleList = getColorsStyles(state.customForms);
    },
    UPDATE_CUSTOM_FORM(state, payload) {
      const index = state.customForms.findIndex((el) => el.dataKey === payload.dataKey);
      if (index !== -1) {
        state.customForms[index] = payload;
        //* trigger update of available options for colors style in feature_type_edit (getters not working)
        state.colorsStyleList = getColorsStyles(state.customForms);
      }
    },
    REMOVE_CUSTOM_FORM(state, payload) {
      state.customForms = state.customForms.filter(form => form.dataKey !== payload);
      state.colorsStyleList = getColorsStyles(state.customForms);
    },
    EMPTY_CUSTOM_FORMS(state) {
      state.customForms = [];
      state.colorsStyleList = [];
    },
    SET_IMPORT_FEATURE_TYPES_DATA(state, payload) {
      state.importFeatureTypeData = payload;
    },
    SET_FILE_TO_IMPORT(state, payload) {
      state.fileToImport = payload;
    },
  },
  getters: {
    feature_type: state => state.feature_types.find(
      (el) => el.slug === state.current_feature_type_slug
    ),
  },
  actions: {
    GET_PROJECT_FEATURE_TYPES({ commit }, project_slug) {
      return axios
        .get(`${this.state.configuration.VUE_APP_DJANGO_API_BASE}projects/${project_slug}/feature-types/`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            commit('SET_FEATURE_TYPES', response.data.feature_types);
            return response;
          }
        })
        .catch((error) => {
          throw error;
        });
    },

    async SEND_FEATURE_TYPE({ state, getters, rootState }, requestType) {
      const data = {
        title: state.form.title.value,
        title_optional: state.form.title_optional.value,
        geom_type: state.form.geom_type.value,
        color: state.form.color.value,
        colors_style: state.form.colors_style.value,
        project: rootState.projects.project.slug,
        customfield_set: state.customForms.map(el => {
          return {
            position: el.position,
            label: el.label,
            name: el.name,
            field_type: el.field_type,
            options: el.options,
          };
        }),
        //'is_editable': true,
      };

      if (requestType === 'post') {
        return axios
          .post(`${this.state.configuration.VUE_APP_DJANGO_API_BASE}feature-types/`, data)
          .then((response) => {
            if (response) {
              const feature_type_slug = response.data.slug;
              const status = response.status;
              return { feature_type_slug, status };
            }
          })
          .catch((error) => {
            throw (error);
          });
      } else if (requestType === 'put') {
        return axios
          .put(`${this.state.configuration.VUE_APP_DJANGO_API_BASE}feature-types/${getters.feature_type.slug}/`, data)
          .then((response) => {
            if (response) {
              const feature_type_slug = response.data.slug;
              const status = response.status;
              return { feature_type_slug, status };
            }
          })
          .catch((error) => {
            throw (error);
          });
      }
    },

    async SEND_FEATURE_SYMBOLOGY({ getters, rootState }, symbology) {
      const data = {
        title: getters.feature_type.title,
        project: rootState.projects.project.slug,
        ...symbology
      };
      return axios
        .put(`${this.state.configuration.VUE_APP_DJANGO_API_BASE}feature-types/${getters.feature_type.slug}/`, data)
        .then((response) => {
          if (response) {
            const feature_type_slug = response.data.slug;
            const status = response.status;
            return { feature_type_slug, status };
          }
        })
        .catch((error) => {
          throw (error);
        });
    },

    async SEND_FEATURES_FROM_GEOJSON({ state, dispatch, rootState }, payload) {
      let { feature_type_slug, geojson } = payload;
      //* check if geojson then build a file
      if(!geojson && !state.fileToImport && state.fileToImport.size === 0 ) return;
      let formData = new FormData();
      let fileToImport;
      let { name, type } = geojson || state.fileToImport;
      if (!name && state.fileToImport) name = state.fileToImport.name;

      if (rootState.projects.project.moderation) {
        if (state.fileToImport && state.fileToImport.size > 0) { //* if data in a binary file, read it as text
          const textFile = await state.fileToImport.text();
          geojson = JSON.parse(textFile);
        }
        const unmoderatedFeatures = pending2draftFeatures(geojson.features);
        geojson= {
          type: 'FeatureCollection', features: unmoderatedFeatures
        };
      }
      fileToImport = new File([JSON.stringify(geojson)], name, { type });

      formData.append('json_file', geojson ? fileToImport : state.fileToImport);
      formData.append('feature_type_slug', feature_type_slug);
      let url =
        this.state.configuration.VUE_APP_DJANGO_API_BASE +
        'import-tasks/';
      return axios
        .post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          if (response && response.status === 200) {
            return dispatch('GET_IMPORTS', {
              feature_type: feature_type_slug
            });
          }
          return response;
        })
        .catch((error) => {
          throw (error);
        });
    },

    GET_IMPORTS({ commit }, { project_slug, feature_type }) {
      let url = `${this.state.configuration.VUE_APP_DJANGO_API_BASE}import-tasks/`;
      if (project_slug) {
        url = url.concat('', `${url.includes('?') ? '&' : '?'}project_slug=${project_slug}`);
      }
      if (feature_type) {
        url = url.concat('', `${url.includes('?') ? '&' : '?'}feature_type_slug=${feature_type}`);
      }
      return axios
        .get(url)
        .then((response) => {
          if (response) {
            commit('SET_IMPORT_FEATURE_TYPES_DATA', response.data);
          }
          return response;
        })
        .catch((error) => {
          throw (error);
        });
    }
  }
};

export default feature_type;
import Vue from 'vue';
import VueRouter from 'vue-router';
import Projects from '../views/Projects.vue';

Vue.use(VueRouter);

let projectBase = 'projet';
if (window.location.pathname.includes('projet-partage')) {
  projectBase = 'projet-partage';
}

const routes = [
  {
    path: '/',
    name: 'index',
    component: Projects
  },
  {
    path: `${projectBase === 'projet' ? '': '/' + projectBase + '/:slug'}/connexion/`,
    name: 'login',
    component: () => import('../views/registration/Login.vue')
  },
  {
    path: `${projectBase === 'projet' ? '': '/' + projectBase + '/:slug'}/my_account/`,
    name: 'my_account',
    component: () => import('../views/My_account.vue')
  },
  {
    path: `${projectBase === 'projet' ? '': '/' + projectBase}/mentions/`,
    name: 'mentions',
    component: () => import('../views/flatpages/with_right_menu.vue')
  },
  {
    path: `${projectBase === 'projet' ? '': '/' + projectBase}/aide/`,
    name: 'aide',
    component: () => import('../views/flatpages/Default.vue')
  },
  // * PROJECT
  {
    path: '/creer-projet/',
    name: 'project_create',
    component: () => import('../views/project/Project_edit.vue')
  },
  {
    path: `/${projectBase}/:slug`,
    name: 'project_detail',
    props: true,
    component: () => import('../views/project/Project_detail.vue'),
  },
  {
    path: `/${projectBase}/:slug/editer`,
    name: 'project_edit',
    component: () => import('../views/project/Project_edit.vue')
  },
  {
    path: '/projet-type/',
    name: 'project_type_list',
    component: () => import('../views/project/Project_type_list.vue')
  },
  {
    path: '/creer-projet/create_from/:slug/',
    name: 'project_create_from',
    component: () => import('../views/project/Project_edit.vue')
  },
  {
    path: `/${projectBase}/:slug/administration-carte/`,
    name: 'project_mapping',
    component: () => import('../views/project/Project_mapping.vue')
  },
  {
    path: `/${projectBase}/:slug/membres/`,
    name: 'project_members',
    component: () => import('../views/project/Project_members.vue')
  },
  // * FEATURE TYPE 
  {
    path: `/${projectBase}/:slug/type-signalement/ajouter/`,
    name: 'ajouter-type-signalement',
    props: true,
    component: () => import('../views/feature_type/Feature_type_edit.vue')
  },
  {
    path: `/${projectBase}/:slug/type-signalement/ajouter/create_from/:slug_type_signal`,
    name: 'dupliquer-type-signalement',
    component: () => import('../views/feature_type/Feature_type_edit.vue')
  },
  {
    path: `/${projectBase}/:slug/type-signalement/:feature_type_slug/`,
    name: 'details-type-signalement',
    component: () => import('../views/feature_type/Feature_type_detail.vue')
  },
  {
    path: `/${projectBase}/:slug/type-signalement/:slug_type_signal/editer/`,
    name: 'editer-type-signalement',
    component: () => import('../views/feature_type/Feature_type_edit.vue')
  },
  {
    path: `/${projectBase}/:slug/type-signalement/:slug_type_signal/symbologie/`,
    name: 'editer-symbologie-signalement',
    component: () => import('../views/feature_type/Feature_type_symbology.vue')
  },
  // * FEATURE 
  {
    path: `/${projectBase}/:slug/signalement/lister/`,
    name: 'liste-signalements',
    component: () => import('../views/feature/Feature_list.vue')
  },
  {
    path: `/${projectBase}/:slug/type-signalement/:slug_type_signal/signalement/ajouter/`,
    name: 'ajouter-signalement',
    component: () => import('../views/feature/Feature_edit.vue')
  },
  {
    path: `/${projectBase}/:slug/type-signalement/:slug_type_signal/signalement/:slug_signal`,
    name: 'details-signalement',
    component: () => import('../views/feature/Feature_detail.vue')
  },
  {
    path: `/${projectBase}/:slug/type-signalement/:slug_type_signal/offline`,
    name: 'offline-signalement',
    component: () => import('../views/feature/Feature_offline.vue')
  },
  {
    path: `/${projectBase}/:slug/type-signalement/:slug_type_signal/signalement/:slug_signal/editer/`,
    name: 'editer-signalement',
    component: () => import('../views/feature/Feature_edit.vue')
  },

  {
    path: '/projet/:slug/catalog/:feature_type_slug',
    name: 'catalog-import',
    component: () => import('../views/Catalog.vue')
  },

  { path: '/:pathMatch(.*)*', name: 'NotFound', component: () => import('../views/NotFound.vue') },
];

const router = new VueRouter({
  mode: 'history',
  base: '/geocontrib/',
  routes,
  routerHistory: [],
  scrollBehavior(to, from, savedPosition) { //* record each route change to keep scroll position
    const fromHistory = Boolean(savedPosition);

    if (fromHistory && this.options.routerHistory.length > 0) {
      this.options.routerHistory.splice(-1, 1);
    } else {
      this.options.routerHistory.push(from);
    }
    return savedPosition || { x: 0, y: 0 };
  },
});

export default router;

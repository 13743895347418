<template>
  <div class="filters-container">
    <div class="ui styled accordion">
      <div
        class="title collapsible-filters"
      >
        FILTRES
        <i
          class="ui icon caret right down"
        />
      </div>
    </div>
    <div class="ui menu filters hidden">
      <div class="item">
        <label>
          Niveau d'autorisation requis
        </label>
        <DropdownMenuItem
          :options="accessLevelOptions"
          v-on="$listeners"
        />
      </div>
      <div class="item">
        <label>
          Mon niveau d'autorisation
        </label>
        <DropdownMenuItem
          :options="userAccessLevelOptions"
          v-on="$listeners"
        />
      </div>
      <div class="item">
        <label>
          Modération
        </label>
        <DropdownMenuItem
          :options="moderationOptions"
          v-on="$listeners"
        />
      </div>
      <div class="right item">
        <label>
          Recherche par nom
        </label>
        <search-projects
          :search-function="SEARCH_PROJECTS"
          v-on="$listeners"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import DropdownMenuItem from '@/components/Projects/DropdownMenuItem.vue';
import SearchProjects from '@/components/Projects/SearchProjects.vue';

export default {
  name: 'ProjectsMenu',

  components: {
    DropdownMenuItem,
    SearchProjects,
  },

  data() {
    return {
      moderationOptions: [
        {
          label: 'Tous',
          filter: 'moderation',
          value: null
        },
        {
          label: 'Projet modéré',
          filter: 'moderation',
          value: 'true'
        },
        {
          label: 'Projet non modéré',
          filter: 'moderation',
          value: 'false'
        },
      ],
      accessLevelOptions: [
        {
          label: 'Tous',
          filter: 'access_level',
          value: null
        },
        {
          label: 'Utilisateur anonyme',
          filter: 'access_level',
          value: 'anonymous'
        },
        {
          label: 'Utilisateur connecté',
          filter: 'access_level',
          value: 'logged_user'
        },
        {
          label: 'Contributeur',
          filter: 'access_level',
          value: 'contributor'
        },
      ],
      userAccessLevelOptions: [
        {
          label: 'Tous',
          filter: 'user_access_level',
          value: null
        },
        {
          label: 'Utilisateur connecté',
          filter: 'user_access_level',
          value: '1'
        },
        {
          label: 'Contributeur',
          filter: 'user_access_level',
          value: '2'
        },
        {
          label: 'Super contributeur',
          filter: 'user_access_level',
          value: '3'
        },
        {
          label: 'Modérateur',
          filter: 'user_access_level',
          value: '4'
        },
        {
          label: 'Administrateur projet',
          filter: 'user_access_level',
          value: '5'
        },
      ]
    };
  },

  computed: {
    ...mapState(['user'])
  },

  created() {
    if (!this.user) {
      this.userAccessLevelOptions.splice(1, 0, {
        label: 'Utilisateur anonyme',
        filter: 'user_access_level',
        value: '0'
      });
    }
  },

  mounted() {
    const el = document.getElementsByClassName('collapsible-filters');

    el[0].addEventListener('click', function() {
      const icon = document.getElementsByClassName('caret');
      icon[0].classList.toggle('right');
      const content = document.getElementsByClassName('filters');
      content[0].classList.toggle('hidden');
      if (content[0].style.maxHeight){
        content[0].style.maxHeight = null;
      } else {
        content[0].style.maxHeight = content[0].scrollHeight + 5 + 'px';
      }
    });
  },

  methods: {
    ...mapActions('projects', [
      'SEARCH_PROJECTS'
    ])
  }
};
</script>

<style lang="less" scoped>
.transition-properties(...) {
  -webkit-transition: @arguments;
  -moz-transition: @arguments;
  -o-transition: @arguments;
  transition: @arguments;
}

.filters-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
	.accordion {
		width: fit-content;
		.collapsible-filters {
			cursor: pointer;
			font-size: 1.25em;
			padding-right: 0;
		}
	}
	.filters {
		width: 100%;
		height:auto;
		min-height: 0;
		max-height:75px;
		margin: 0 0 1em 0;
    border: none;
    box-shadow: none;
		.transition-properties(max-height 0.2s ease-out;);
		.item {
			display: flex;
			flex-direction: column;
			align-items: flex-start !important;

			padding: 0.4em 0.6em 0.4em 0;

			label {
				margin-bottom: 0.2em;
				font-size: 0.9em;
				font-weight: 600;
			}
		}
		.item {
			width: 25%;
		}
    .item::before {
			width: 0;
		}
    .right.item {
      padding-right: 0;
    }
		.right.item::before {
			width: 0;
		}
	}
	.filters.hidden {
		max-height: 0;
		overflow: hidden;
		border: none;
	}
}
</style>

const axios = require('axios');
import Vue from 'vue';

import App from './App.vue';
import './registerServiceWorker';
import router from '@/router';
import store from '@/store';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import '@/assets/resources/leaflet-control-geocoder-1.13.0/Control.Geocoder.css';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
// Multiselect installation
import 'vue-multiselect/dist/vue-multiselect.min.css';

library.add(fas);

Vue.component(FontAwesomeIcon);

Vue.config.productionTip = false;

// gestion mise à jour du serviceWorker et du precache
var refreshing=false;
if(navigator.serviceWorker){
  navigator.serviceWorker.addEventListener('controllerchange', () => {
    // We'll also need to add 'refreshing' to our data originally set to false.
    if (refreshing) return;
    refreshing = true;
    // Here the actual reload of the page occurs
    window.location.reload();
  });
}



let onConfigLoaded = function(config){
  store.commit('SET_CONFIG', config);

  // set title and favico
  document.title= config.VUE_APP_APPLICATION_NAME+' '+config.VUE_APP_APPLICATION_ABSTRACT;
  let link = document.createElement('link');
  link.id = 'dynamic-favicon';
  link.rel = 'shortcut icon';
  link.href = config.VUE_APP_APPLICATION_FAVICO;
  document.head.appendChild(link);

  window.proxy_url=config.VUE_APP_DJANGO_API_BASE+'proxy/';
  axios.all([
    store.dispatch('USER_INFO'),
    store.dispatch('projects/GET_PROJECTS'),
    store.dispatch('GET_STATIC_PAGES'),
    store.dispatch('GET_USER_LEVEL_PROJECTS'),
    store.dispatch('map/GET_AVAILABLE_LAYERS'),
    store.dispatch('GET_USER_LEVEL_PERMISSIONS'),
    store.dispatch('GET_LEVELS_PERMISSIONS'),
  ]).then(axios.spread(function () {
    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app');
  }));

};

axios.get('./config/config.json')
  .catch((error)=>{
    console.error(error);
    console.log('try to get From Localstorage');
    let conf=localStorage.getItem('geontrib_conf');
    if(conf){
      onConfigLoaded(JSON.parse(conf));
    }
  })
  .then((response) => {
    if (response && response.status === 200) {
      localStorage.setItem('geontrib_conf',JSON.stringify(response.data));
      onConfigLoaded(response.data);
    }
  })
  .catch((error) => {
    throw error;
  });

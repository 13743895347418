<template>
  <div id="search-projects">
    <input
      v-model="text"
      type="text"
      placeholder="Rechercher un projet ..."
    >
  </div>
</template>

<script>
import _ from 'lodash';
import { mapMutations } from 'vuex';

export default {
  name: 'SearchProjects',

  props: {
    searchFunction: {
      type: Function,
      default: () => { return {}; }
    }
  },

  data() {
    return {
      text: null
    };
  },

  watch: {
    text: _.debounce(function(newValue) {
      this.$emit('loading', true);
      this.SET_CURRENT_PAGE(1);
      this.searchFunction(newValue)
        .then(() => {
          this.$emit('loading', false);
        })
        .catch((err) => {
          if (err.message) this.$emit('loading', false);
        });
    }, 100)
  },

  methods: {
    ...mapMutations('projects', [
      'SET_CURRENT_PAGE'
    ])
  }
};
</script>

<style lang="less" scoped>
#search-projects {
  display: block;
  height: 100%;
  min-height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size: 1rem;
  input {
    display: block;
    width: 100%;
    height: 100%;
    text-align: left;
    color: #35495e;
    padding: 8px 40px 8px 8px;
    border: 1px solid #ced4da;
    font-size: 1rem;
    font-family: 'Roboto Condensed', Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif !important;
  }
  input:focus {
    outline: none !important;
    box-shadow: 0 0 1px grey;
  }
}
</style>
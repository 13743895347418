import axios from '@/axios-client.js';
import { mapUtil } from '@/assets/js/map-util.js';


const map = {
  namespaced: true,
  state: {
    map: null,
    basemaps: null,
    basemapsToDelete: [],
    features: [],
    geojsonFeatures: null,
    availableLayers: null,
  },
  mutations: {
    SET_MAP(state, payload) {
      state.map = payload;
    },
    SET_LAYERS(state, availableLayers) {
      state.availableLayers = availableLayers;
    },
    SET_GEOJSON_FEATURES(state, geojsonFeatures) {
      state.geojsonFeatures = geojsonFeatures;
    },
    SET_BASEMAPS(state, basemaps) {
      state.basemaps = basemaps;
    },
    CREATE_BASEMAP(state, id) {
      state.basemaps = [...state.basemaps, { id, title: '', layers: [], errors: [] }];
    },
    UPDATE_BASEMAPS(state, basemaps) {
      state.basemaps = basemaps;
    },
    UPDATE_BASEMAP(state, { title, id, layers, errors }) {
      const index = state.basemaps.findIndex((el) => el.id === id);
      if (index !== -1) {
        state.basemaps[index].title = title;
        state.basemaps[index].errors = errors;
        if (layers) {
          state.basemaps[index].layers = layers;
        }
      }
    },
    DELETE_BASEMAP(state, basemapId) {
      state.basemaps = state.basemaps.filter(el => el.id !== basemapId);
      state.basemapsToDelete.push(basemapId);
    },
    REMOVE_BASEMAP_ID_TO_DELETE(state, basemapId) {
      state.basemapsToDelete = state.basemapsToDelete.filter(el => el !== basemapId);
    },
    REPLACE_BASEMAP_LAYERS(state, { basemapId, layers }) {
      const index = state.basemaps.findIndex((el) => el.id === basemapId);
      if (index !== -1) {
        state.basemaps[index].layers = layers;
      }
    },
    DELETE_BASEMAP_LAYER(state, { basemapId, layerId }) {
      const index = state.basemaps.findIndex((el) => el.id === basemapId);
      if (index !== -1) {
        state.basemaps[index].layers = state.basemaps[index].layers.filter((el) => el.dataKey !== layerId);
      }
    },
    UPDATE_BASEMAP_LAYER(state, { basemapId, layerId, layer }) {
      const index = state.basemaps.findIndex((el) => el.id === basemapId);
      if (index !== -1) {
        state.basemaps[index].layers = state.basemaps[index].layers.map(
          (el) => el.dataKey === layerId ? layer : el
        );
      }
    },
  },

  getters: {
    basemapMaxId: (state) => state.basemaps.reduce((acc, curr) => {
      if (curr.id > acc) {
        return curr.id;
      } else {
        return acc;
      }
    }, 0)
  },

  actions: {
    GET_AVAILABLE_LAYERS({ commit }) {
      return axios
        .get(`${this.state.configuration.VUE_APP_DJANGO_API_BASE}layers/`)
        .then((response) => (commit('SET_LAYERS', response.data)))
        .catch((error) => {
          throw error;
        });
    },

    GET_BASEMAPS({ commit }, project_slug) {
      return axios
        .get(`${this.state.configuration.VUE_APP_DJANGO_API_BASE}base-maps/?project__slug=${project_slug}`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            commit('SET_BASEMAPS', response.data);
          }
          return response;
        })
        .catch((error) => {
          throw error;
        });
    },

    INITIATE_MAP({ commit }, el) { //todo: since this function is not anymore called in different components, it would better to move it in project_details.vue
      let mapDefaultViewCenter = [46, 2]; // defaultMapView.center;
      let mapDefaultViewZoom = 5; // defaultMapView.zoom;
      mapUtil.createMap(el, {
        mapDefaultViewCenter,
        mapDefaultViewZoom,
      });
      commit('SET_MAP', mapUtil.getMap());

      mapUtil.addLayers(
        null,
        this.state.configuration.DEFAULT_BASE_MAP_SERVICE,
        this.state.configuration.DEFAULT_BASE_MAP_OPTIONS,
        this.state.configuration.DEFAULT_BASE_MAP_SCHEMA_TYPE,
      );

      // Remove multiple interactions with the map
      //mapUtil.getMap().dragging.disable();
      mapUtil.getMap().doubleClickZoom.disable();
      mapUtil.getMap().scrollWheelZoom.disable();

    },

    async SAVE_BASEMAPS({ state, rootState, dispatch }, newBasemapIds) {
      const DJANGO_API_BASE = this.state.configuration.VUE_APP_DJANGO_API_BASE;
      function postOrPut(basemap) {
        basemap['project'] = rootState.projects.project.slug;
        if (newBasemapIds.includes(basemap.id)) {
          return axios
            .post(`${DJANGO_API_BASE}base-maps/`, basemap)
            .then((response) => response)
            .catch((error) => {
              console.error(error);
              return error;
            });
        } else {
          return axios
            .put(`${DJANGO_API_BASE}base-maps/${basemap.id}/`, basemap)
            .then((response) => response)
            .catch((error) => {
              console.error(error);
              return error;
            });
        }
      }

      function deleteBMap(basemapId) {
        //* delete in the backend the basemaps that was rewoved from the front
        return dispatch('DELETE_BASEMAP', basemapId)
          .then((response) => response);
      }

      const promisesResult = await Promise.all(
        [
          ...state.basemaps.map((basemap) => postOrPut(basemap)),
          ...state.basemapsToDelete.map((basemapId) => deleteBMap(basemapId))
        ]
      );
      state.basemapsToDelete = [];
      return promisesResult;
    },

    DELETE_BASEMAP({ commit }, basemapId) {
      let url = `${this.state.configuration.VUE_APP_DJANGO_API_BASE}base-maps/` + basemapId;
      return axios
        .delete(url)
        .then((response) => {
          if (response && response.status === 204) {
            commit('REMOVE_BASEMAP_ID_TO_DELETE', basemapId);
            return response;
          }
        })
        .catch((error) => {
          console.error(error);
          return error;
        });
    }
  },
};
export default map;
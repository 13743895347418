/* eslint-disable no-console */

import { register } from 'register-service-worker';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      );
    },
    registered (registration) {
      //console.log('Service worker has been registered.')
      console.log(
        'Service worker has been registered and now polling for updates.'
      );
      setInterval(() => {
        registration.update();
      }, 10000); // every 10 seconds
    },
    cached () {
      console.log('Content has been cached for offline use.');
    },
    updatefound () {
      console.log('New content is downloading.');
    },
    updated (registration) {
      alert('Une nouvelle version de l\'application est disponible, l\'application va se recharger');
      console.log('New content is available; please refresh.');
      //
      if (!registration || !registration.waiting) return;
      // Send message to SW to skip the waiting and activate the new SW
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      //window.location.reload(true);
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.');
    },
    error (error) {
      console.error('Error during service worker registration:', error);
    }
  });
}

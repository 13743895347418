import axios from '@/axios-client.js';

const projectAPI = {

  async getProject( baseUrl, projectSlug ) {
    const response = await axios.get(
      `${baseUrl}projects/${projectSlug}/`
    );
    if (
      response.status === 200 &&
      response.data
    ) {
      return response.data;
    } else {
      return null;
    }
  },

  async getProjectSubscription({ baseUrl, projectSlug }) {
    const response = await axios.get(
      `${baseUrl}projects/${projectSlug}/subscription/`
    );
    if (
      response.status === 200 &&
      response.data
    ) {
      return response.data;
    } else {
      return null;
    }
  },

  async subscribeProject({ baseUrl, projectSlug, suscribe }) {
    const response = await axios.put(
      `${baseUrl}projects/${projectSlug}/subscription/`,
      { is_suscriber: suscribe }
    );
    if (
      response.status === 200 &&
      response.data
    ) {
      return response.data;
    } else {
      return null;
    }
  },

  async getProjects(baseUrl, filters, page) {
    try {
      const url = `${baseUrl}projects/?page=${page}`;

      let filteredUrl;
      if (Object.values(filters).some(el => el && el.length > 0)) {
        filteredUrl = url;
        for (const filter in filters) {
          if (filters[filter]) {
            filteredUrl = filteredUrl.concat('', `&${filter}=${filters[filter]}`);
          }
        }
      }

      const response = await axios.get(filteredUrl ? filteredUrl : url);
      if (response.status === 200 && response.data) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  async getProjectTypes( baseUrl ) {
    const response = await axios.get(
      `${baseUrl}project-types/`
    );
    if (
      response.status === 200 &&
      response.data
    ) {
      return response.data;
    } else {
      return null;
    }
  },

  async deleteProject(baseUrl, projectSlug) {
    const response = await axios.delete(
      `${baseUrl}projects/${projectSlug}/`
    );
    if ( response.status === 204 ) {
      return 'success';
    } else {
      return null;
    }
  },
};

export default projectAPI;
